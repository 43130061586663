// Import libs
import React from "react";

// Import components
import Seo from "../../components/Seo";
import Layout from "../../components/Layout";
import {
	Button,
	Cover
} from "@windish/shared";

// Import page-specific CSS
import "./index.css"

// Page constants
const pageTitle = "Sent"
const pageDescription = "Confirmation page for sent form";

// Page
export default function FormSent() {

	function goBack() {
		window.history.go(-1);
	}

	// Render page
	return (
			<Layout pageTitle={pageTitle}>
				<Cover
					heading="Thank you!"
					subheading="The form was submitted successfully. I try to send my initial response within a single working day; please allow for time zone differences."
				>
          <Button type="cta" label="Back to previous page" action={goBack} />
				</Cover>
			</Layout>
	);

}

// Page metadata
export const Head = () => (
	<Seo
		title={pageTitle}
		description={pageDescription}
	>
		<script type="application/ld+json">
			{`
        {
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "${pageTitle}",
          "description": "${pageDescription}",
          "url": "https://vindis.io/sent",
          "copyrightHolder": "Aljaž Vindiš",
          "copyrightYear": 2022,
					"author": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					},
					"publisher": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					}
        }
      `}
		</script>
	</Seo>
)
